import { Link as PrimativeLink } from "@remix-run/react";
import type { ComponentProps } from "react";
import { forwardRef } from "react";

import { useGlobalContext } from "../../global/UIProvider.js";
import cn from "../../util/cn.js";
import PostLink from "./PostLink.js";

export type LinkTo = ComponentProps<typeof PrimativeLink>["to"];

export type PrefetchBehavior = ComponentProps<typeof PrimativeLink>["prefetch"];

type LinkProps = ComponentProps<typeof PrimativeLink> & {
  postTo?: boolean;
  to: LinkTo;
  prefetch?: PrefetchBehavior;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, postTo, prefetch, ...props }, ref) => {
    const { prefetch: globalPrefetch } = useGlobalContext();

    if (postTo) {
      return <PostLink className={className} {...props} />;
    }

    const propsWithDefaults: LinkProps = {
      prefetch: prefetch ?? globalPrefetch,
      ...props,
    };
    return (
      <PrimativeLink
        ref={ref}
        {...propsWithDefaults}
        className={cn(
          // "focus:outline-none focus:ring-2 focus:ring-accent active:outline-none",
          className
        )}
      />
    );
  }
);

export default Link;
